<template>
  <div class="advertisement-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Quảng cáo</b-card-title>
        <crud-buttons
          name="quảng cáo"
          modal="modal-advertisement"
          :selected-rows="selectedRows"
          :show-import="false"
          :show-export="false"
          @on-delete="onDelete"
          @on-add="resetItem"
        />
      </b-card-header>
      <b-card-body>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"

              :pagination-options="{
                enabled: true,
                perPage: serverParams.length,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'image'">
                  <b-img
                    class="rounded border-1"
                    v-bind="mainProps"
                    :src="props.row.type === 'image' ? `https://api.vtelehealth.vn:8443/media/view?token=${encodeURIComponent(props.row.source_link)}-300.webp` : props.row.image"
                  />
                </span>

                <span v-else-if="props.column.field === 'title'">
                  <span>{{ props.row.title }}</span>
                  <small
                    v-if="props.row.url"
                    class="text-muted"
                  >
                    <br>
                    URL: <b-link>{{ props.row.url }}</b-link>
                  </small>

                </span>

                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    pill
                    :variant="resolveStatusVariant(props.row.status).variant"
                    class="font-small-1"
                  >
                    {{ resolveStatusVariant(props.row.status).title }}
                  </b-badge>

                </span>
                <span v-else-if="props.column.field === 'type'">
                  <b-badge
                    pill
                    :variant="resolveTypeVariant(props.row.type).variant"
                    class="font-small-1"
                  >
                    {{ resolveTypeVariant(props.row.type).title }}
                  </b-badge>

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="quảng cáo"
                    modal="modal-advertisement"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.length"
                      :options="['10', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.length"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <advertisement-modal
      :item="item"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BPagination,
  BFormSelect,
  BSpinner,
  BImg,
  BBadge,
  BLink,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
import useAdvertisementList from "./useAdvertisementList";
import AdvertisementModal from '../modal/AdvertisementModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BSpinner,
    TableActions,
    CrudButtons,
    VueGoodTable,
    AdvertisementModal,
    BImg,
    BBadge,
    BLink,
  },
  data() {
    return {
      mainProps: {
        width: 200,
        height: 120,
      },
    };
  },

  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      department,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      deleteAdvertisement,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveTypeVariant,
    } = useAdvertisementList();

    return {
      analytics,
      item,
      columns,
      rows,
      department,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      deleteAdvertisement,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveTypeVariant,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(apartment) {
      this.deleteAdvertisement(apartment._id);
    },
    onEdit(apartment) {

    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.advertisement-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 50px !important;
            }
            &:nth-child(3) {
               min-width: 80px !important;
            }

            &:nth-child(5) {
               width: 200px !important;
            }
            &:nth-child(6) {
               width: 140px !important;
            }
            &:nth-child(7) {
               width: 80px !important;
            }
          }
        }
      }
    }
}
</style>
